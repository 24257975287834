













































.box-element {
  padding: 1.25rem;
  margin-bottom: 20px;
  border: none;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  background-color: #fff;
  min-height: 200px;
  width: 100%;
  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 9px 12px rgba(0, 0, 0, 0.1);
  }
  .top-box {
    min-height: 80px;
  }
  .box-bottom {
    min-height: calc(200px - 80px - 1.25rem);
  }
  .box-title {
    h4 {
      margin-bottom: 8px;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-size: 15px;
      font-weight: 700;
      margin-top: 0;
      color: #6c757d;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    }
  }
  .box-value {
    h3 {
      color: #2f394e !important;
      font-weight: 600;
      font-size: clamp(18px, 3vw, 24px)
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      color: #6c757d;
      margin-left: 5px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
  .box-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 26px;
    z-index: 5;
    color: #fff;
    box-shadow: 0 0px 2px 5px rgba(0, 0, 0, 0.1), 0 0px 2px 5px rgba(0, 0, 0, 0.1);
    background: #009750;
  }
}
